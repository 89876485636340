import React, { useEffect, useState } from 'react'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Spinner } from 'reactstrap'
import { images, toastError, toastSuccess } from '../../../utils/constants';
import "./Participants.css";
import { createElement, getTeams } from "../../../apis/element";
import { getCalendar, updateCalendar } from '../../../apis/calendars';

export default function Participants({ isSmallScreen, isDeleteTeam, createdTeam, setCreatedTeam, selectedTeam, setSelectedCalendar, getElementsHandler, userLogin, handleNavigation, selectedCalendar }) {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [selectParticipant, setSelectParticipant] = useState(null);
    const [isUpdateLoading, setIsUpdateLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [allParticipants, setAllParticipants] = useState([]);

    useEffect(() => {
        getTeams()
            .then(({ data }) => {
                if (selectedCalendar) {
                    const selectedParticipant = data.find(team => team.id === selectedCalendar.teamId);
                    const selectedTeam = selectedParticipant ? selectedParticipant : data[0];
                    setSelectParticipant(selectedTeam);
                    if (selectedTeam.id !== selectedCalendar.teamId) {
                        updateParticipantHandler(selectedTeam.id);
                    } else setIsLoading(false);
                } else setIsLoading(false);
                setAllParticipants(data);
            })
            .catch(err => {
                setIsLoading(false);
                toastError(err.response?.data?.message, "top-right");
            })
    }, [selectedCalendar])

    useEffect(() => {
        if (createdTeam && selectedTeam && selectedCalendar && !isDeleteTeam) {
            updateParticipantHandler(selectedTeam?.id);
        }
    }, [createdTeam]);


    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    }

    const updateParticipantHandler = (teamId = selectParticipant.id, isShowMessage = false) => {
        setIsUpdateLoading(true);
        updateCalendar(selectedCalendar.id, { teamId })
            .then(() => {
                getCalendar(selectedCalendar.id)
                    .then(({ data }) => {
                        setSelectedCalendar(data);
                        setCreatedTeam(false);
                        setIsUpdateLoading(false);
                        setIsLoading(false);
                        isShowMessage && toastSuccess("", isSmallScreen ? "bottom-right" : "top-right");
                    })
                    .catch(err => {
                        setIsUpdateLoading(false);
                        toastError(err.response?.data?.message, "top-right");
                    })
            })
            .catch(err => {
                setIsUpdateLoading(false);
                toastError(err.response?.data?.message, "top-right");
            })
    }

    const handleCreateTeam = (e) => {
        e.preventDefault();
        const payload = {
            type: "team",
            participants: [userLogin.email]
        }
        createElement(payload).then(async ({ data }) => {
            getElementsHandler(data.id, "team", true);
            toastSuccess("", isSmallScreen ? "bottom-right" : "top-right");
        }).catch(err => {
            toastError(err.response?.data?.message, "top-right");
        })
    }

    const handleResetParticipantHandler = () => {
        const selectedParticipant = allParticipants.find(team => team.id === selectedCalendar.teamId);
        setSelectParticipant(selectedParticipant);
    }

    return (
        isLoading ? <div className='d-flex justify-content-center'>
            <Spinner color="primary" />
        </div> :
            <div className='participants-main-container'>
                <div>
                    <div className='calendar-main-title-container'>
                        <div className="back-button" onClick={() => handleNavigation("2", "left", `/calendar/${selectedCalendar.name}`)}>
                            <img src={images.backIcon} alt="icon" />
                            <label className="calendar-header">Calendar</label>
                        </div>
                        <h1 className="calendar-main-title">Participants</h1>
                    </div>
                    <h6 className='participants-label'>Participants</h6>
                    <Dropdown className='participants-dropdown mb-3' isOpen={dropdownOpen} toggle={toggleDropdown} >
                        <DropdownToggle className='ps-2 d-flex justify-content-between' tag="div">
                            <span>{selectParticipant?.name}</span>
                            <div className="participants-dropdown-icon-container">
                                <img src={images.dropdownIcon} alt="icon" />
                            </div>
                        </DropdownToggle>
                        <DropdownMenu>
                            {allParticipants.map(participant => (
                                <DropdownItem key={participant.id} onClick={() => setSelectParticipant(participant)}>
                                    {participant.name}
                                </DropdownItem>
                            ))}
                        </DropdownMenu>
                    </Dropdown>
                    {'You can create a new Team with participants '}
                    <a href="" onClick={(e) => handleCreateTeam(e)}>at this link.</a>
                </div>
                {selectParticipant?.id !== selectedCalendar?.teamId && !isLoading &&
                    <div className="editable-button-container mt-4 justify-content-center">
                        <button type="button" className='btn-cancel' onClick={handleResetParticipantHandler}>Cancel</button>
                        <button type="button" className='btn-save' onClick={() => updateParticipantHandler(selectParticipant.id, true)}>
                            {isUpdateLoading ? <Spinner size="sm" color="primary" /> : "Save"}
                        </button>
                    </div>}
            </div>
    )
}
