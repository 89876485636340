import React, { useEffect, useRef, useState } from 'react'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Input, Label, Spinner } from 'reactstrap'
import "./CalendarDetails.css"
import { images, toastSuccess } from "../../../utils/constants";
import { updateCalendar } from "../../../apis/calendars";
import { NavLink } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { getElements } from "../../../apis/element";

export default function CalendarDetails({
  isSmallScreen,
  isLoading: loading,
  calendarLink,
  handleNavigation,
  setCalendarList,
  setSelectedCalendar,
  selectedCalendar,
  calendars,
  zoomEnabled
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isEditable, setEditable] = useState(false);
  const [linkCopied, setLinkCopied] = useState('Copy');
  const [calendarDetail, setCalendarDetail] = useState(selectedCalendar);
  const [location, setLocation] = useState([{ id: "1", name: "Google Meet" }]);
  const formikRef = useRef()

  useEffect(() => {
    setCalendarDetail(selectedCalendar);
    getElements("location").then(res => {
      const locationList = zoomEnabled ? [{ id: "1", name: "Google Meet" }, { id: "2", name: "Zoom Meet" }]
        : [{ id: "1", name: "Google Meet" }];
      setLocation([...locationList, ...res.data])
    });
    setLinkCopied('Copy');
  }, [selectedCalendar, zoomEnabled])

  const copyLinkToClipboard = () => {
    const domain = window.location.host;
    navigator.clipboard.writeText(`${domain}/public${calendarLink}/${selectedCalendar.link}`)
      .then(() => setLinkCopied('Copied!'));
  };

  const toggleDropdown = () => {
    if (isEditable) setDropdownOpen(!dropdownOpen);
    else setEditable(true);
  }

  const handleCancelUpdate = (resetForm) => {
    resetForm();
    setEditable(false);
  }

  const handleMeetingDetails = (values) => {
    values.link = values.link.split(" ").join("-");
    const newValues = Object.keys(values).reduce((p, key) => {
      if (values[key] !== "" && (values[key] !== selectedCalendar[key])) return { ...p, [key]: values[key] }
      else return p
    }, {});
    if (Object.keys(newValues).length) updateMeetingCalendar({ ...newValues });
    else setEditable(!isEditable);
  }

  const updateMeetingCalendar = (updateData) => {
    setIsLoading(true);
    updateCalendar(selectedCalendar.id, updateData)
      .then(() => {
        const data = Object.assign([], calendars);
        const calendarIndex = data.findIndex(cal => cal.id === selectedCalendar.id);
        Object.assign(data[calendarIndex], updateData);
        setCalendarList(data);
        toastSuccess("", isSmallScreen ? "bottom-right" : "top-right");
        setSelectedCalendar(data[calendarIndex]);
        setEditable(!isEditable);
        setIsLoading(false);
      }).catch(err => {
        formikRef.current.setFieldError('link', err.response.data.message);
        setIsLoading(false);
      })
  }

  const handleChangeBooking = (setFieldValue,bookingEnabled) => {
    setFieldValue("bookingEnabled",!bookingEnabled);
    setEditable(true);
  }
  return (
    <>
      {loading ?
        <div className='d-flex justify-content-center'>
          <Spinner color="primary" />
        </div>
        :
        <Formik
          initialValues={{
            description: calendarDetail?.description || '',
            duration: calendarDetail?.duration || '',
            location: calendarDetail?.location || '',
            name: calendarDetail?.name || '',
            link: calendarDetail?.link || '',
            bookingEnabled:calendarDetail?.bookingEnabled || false,
          }}
          innerRef={formikRef}
          enableReinitialize={true}
          onSubmit={(values) => handleMeetingDetails(values)}
          validationSchema={validations}
        >
          {({
            values,
            handleSubmit,
            errors,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
            resetForm,
            setFieldError
          }) => (
            <div className="d-flex flex-column justify-content-between flex-grow-1">
              <div>
                <div className='calendar-main-title-container'>
                  <div className="back-button" onClick={() => handleNavigation("2", "left")}>
                    <img src={images.backIcon} alt="icon" />
                    <label className="calendar-header">Calendar details</label>
                  </div>
                  <div className="d-flex justify-content-between">
                    <h1 className="calendar-main-title">Meeting details</h1>
                  </div>
                </div>
                <FormGroup className='mb-0'>
                  <h6 className='meeting-label'>Name</h6>
                  <Input onClick={() => setEditable(true)}
                    readOnly={!isEditable}
                    className='txt-calendar-name'
                    type="text" name="name"
                    placeholder="Enter Calendar name"
                    onBlur={handleBlur}
                    value={values.name}
                    onChange={handleChange}
                  />
                  <p className="formik-error mb-0">{errors.name && touched.name && errors.name}</p>
                </FormGroup>

                <h6 className='meeting-label'>Link</h6>
                <div className='calendar-link-component'>
                  <div className="main-calendar-link-container me-1 d-flex" onClick={() => setEditable(true)}>
                    <span>/</span>
                    <Input
                      onFocus={() => setEditable(true)}
                      className="txt-calendar-link mb-0"
                      type="text" name="link"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.link}
                      placeholder="Enter calendar link" />
                  </div>
                  <div className='d-flex'>
                    <div className='btn-copy-calendar-link me-1' onClick={copyLinkToClipboard}>{linkCopied}</div>
                    <NavLink to={`/public${calendarLink}/${selectedCalendar?.link}`} className='btn-open-link'
                      target="_blank">
                      ↗
                    </NavLink>
                  </div>
                </div>
                <p className="formik-error mb-3">{errors.link && touched.link && errors.link}</p>

                <h6 className='meeting-label'>Description</h6>
                <Input
                  type="textarea"
                  onClick={() => setEditable(true)}
                  readOnly={!isEditable}
                  name="description"
                  className='description-textarea'
                  placeholder="Please insert here an address or location information"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.description}
                />
                <p className="formik-error">{errors.description && touched.description && errors.description}</p>

                <h6 className='meeting-label'>Duration</h6>
                <div onClick={() => setEditable(true)} className='duration-container d-flex ps-2 p align-items-center'>
                  <label>Minutes</label>
                  <Input
                    type="text"
                    readOnly={!isEditable}
                    name="duration"
                    className='txt-duration mb-0'
                    placeholder="15"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.duration}
                  />
                </div>
                <p className="formik-error mb-3">{errors.duration && touched.duration && errors.duration}</p>
                <h6 className='meeting-label'>Location</h6>
                <Dropdown className='duration-dropdown mb-3' isOpen={dropdownOpen && isEditable}
                  toggle={toggleDropdown}>
                  <DropdownToggle className='d-flex justify-content-between ps-2' tag="div">
                    <span>{values.location}</span>
                    <div className="dropdown-icon-container">
                      <img className="dropdown-icon" src={images.dropdownIcon} alt="icon" />
                    </div>
                  </DropdownToggle>
                  <DropdownMenu>
                    {location.map(location => (
                      <DropdownItem key={location.id} onClick={() => setFieldValue("location", location.name)}>
                        {location.name}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </Dropdown>
                <p className="formik-error">{errors.location && touched.location && errors.location}</p>
                <h6 className='meeting-label'>Booking</h6>
                <FormGroup check className='mb-3'>
                  <Input
                    id="bookingEnabled"
                    name="bookingEnabled"
                    type="checkbox"
                    checked={values.bookingEnabled}
                    value={values.bookingEnabled}
                    onChange={() => handleChangeBooking(setFieldValue,values.bookingEnabled)}
                  />
                  <Label check for="bookingEnabled">
                   Allow users to book times. Disabling this will only show available times.
                  </Label>
                </FormGroup>
                <p className="info-text">The above information will appear on the public booking page</p>
              </div>
              {isEditable && <div className="editable-button-container">
                <button type="button" className='btn-cancel' onClick={() => handleCancelUpdate(resetForm)}>Cancel
                </button>
                <button type="button" className='btn-save' onClick={handleSubmit}>
                  {isLoading ? <Spinner size="sm" color="primary" /> : "Save"}
                </button>
              </div>}
            </div>
          )}
        </Formik>
      }
    </>
  )
}
const validations = Yup.object().shape({
  link: Yup.string().required('Link is required')
    .matches(/^[a-zA-Z0-9 -]+$/, "You are trying to use characters not compatible with a URL"),
  duration: Yup.number().typeError("Only numbers are allowed!")
    .min(5, "Duration is too sort!").max(360, "Duration is too long!"),
});
