import React, { useState } from 'react'
import { Spinner } from 'reactstrap';
import { images,toastError,toastSuccess } from "../../../utils/constants";
import "./Location.css";
import { useLocation } from 'react-router-dom';
import {deleteElement} from "../../../apis/element";

export default function Location({isSmallScreen, handleNavigation, selectedElement, getElementsHandler }) {
  const { pathname } = useLocation();
  const path = pathname.split("/");
  const [isLoadingDelete, setLoadingDelete] = useState(false);

  const deleteLocationHandler = () => {
    setLoadingDelete(true);
    deleteElement(selectedElement?.id)
      .then(() => {
        getElementsHandler(null,"",false,true);
        setLoadingDelete(false);
        toastSuccess("", isSmallScreen ? "bottom-right" : "top-right");
        handleNavigation("1", "right", "/");
      }).catch(err => {
      setLoadingDelete(false);
      console.log(err,'s');
      toastError(err.response?.data?.message, "top-right");
    })
  }
  return (
    <div className='flex-grow-1 d-flex flex-column justify-content-between'>
      <div>
        <div className='calendar-main-title-container'>
          <div className="back-button" onClick={() => handleNavigation("1", "right", "/")}>
            <img src={images.backIcon} alt="icon" />
            <label className="calendar-header">Home</label>
          </div>
          <h1 className="calendar-main-title">{selectedElement?.name}</h1>
        </div>
        <div className='location-container mb-3'>
          <div className={`location-item ${path[2] === "locationdetails" ? "selected" : ""}`}
               onClick={() => handleNavigation("3", "left", "/location/locationdetails")}>
            <div className='location-icon icon-container'>
              <img src={images.physicalMeetingIcon} alt="location-icon" />
            </div>
            <div className="location-title-container border-0">
              <h6 className="location-item-title mb-0">
                Location
              </h6>
              <div className="direction-right pe-3">
                <img src={images.angleRightIcon} alt="angle_right" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='d-flex justify-content-center'>
        <button className="btn-delete-availability" onClick={() => deleteLocationHandler()}>
          {isLoadingDelete ? <Spinner size={"sm"} color='primary' /> : "Delete Location"}
        </button>
      </div>
    </div>
  )
}
