import React, { useState } from 'react'
import { Modal, ModalBody, ModalFooter, Spinner } from 'reactstrap';
import "./ReConnectModal.css"
import { images, toastSuccess, toastError } from "../../utils/constants";
import { useGoogleLogin } from 'react-google-login';
import { authenticateWithGoogle } from "../../apis/auth";
import { CLIENT_ID, SCOPES } from "../../calendarConfig";

export default function ReConnectModal({setIsRefreshTokenExpired,setUserLogin,isRefreshTokenExpired }) {
    const [isOpenModel, setOpenModel] = useState(isRefreshTokenExpired);
    const [isUserLoading, setIsUserLoading] = useState(false);

    const toggleModalHandler = () => {
        setOpenModel(!isOpenModel);
    }

    const handleSignIn = ({ code, accessToken }) => {
        setIsUserLoading(true);
        authenticateWithGoogle({ code, accessToken, timezone: Intl.DateTimeFormat().resolvedOptions().timeZone })
            .then(({isRefreshTokenExpired, user }) => {
                setUserLogin(user)
                localStorage.setItem('user', JSON.stringify(user));
                setIsRefreshTokenExpired(isRefreshTokenExpired);
                setIsUserLoading(false);
                setOpenModel(isRefreshTokenExpired);
            }).catch(err => {
                localStorage.clear();
                setIsUserLoading(false);
                toastError(err, "top-right");
            })
    }

    const { signIn } = useGoogleLogin({
        clientId: CLIENT_ID,
        isSignedIn: true,
        responseType: "code",
        accessType: 'offline',
        prompt: "consent",
        scope: SCOPES,
        onSuccess: handleSignIn,
        onFailure: (err) => toastError(err, "top-right"),
        onAutoLoadFinished: (res) => {
            setIsUserLoading(res);
        }
    })

    return (
        <Modal modalClassName="re-connect-modal-container"
            className='re-connect-modal' isOpen={isOpenModel}
            toggle={toggleModalHandler}>
            <ModalBody className='pt-3 p-0 text-center mb-4 '>
                <p className='re-connect-modal-title text-center mb-0'>We could not access your Google Calendar</p>
            </ModalBody>
            <ModalFooter className='re-connect-modal-footer border-0 p-0 justify-content-center '>
                <button className='btn-update my-0' onClick={signIn} >
                    {isUserLoading ? <Spinner size="sm" color="light" /> : "Re-connect"}
                </button>
                <button className='btn-cancel my-0' onClick={toggleModalHandler}>Cancel</button>
            </ModalFooter>
        </Modal>
    )
}
