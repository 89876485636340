import React, { useState } from 'react';
import { Col, Input, Row } from 'reactstrap';
import { images } from "../../utils/constants";
import "./CustomTimePicker.css"
import TimePicker from 'react-time-picker-input';
import "react-time-picker-input/dist/components/TimeInput.css";

export default function CustomTimePicker({ slot, handleDeleteSlot, handleSlotChange }) {
    return (
        <div className='mb-1'>
            <Row className='time-week-main-container m-0'>
                <Col xs={11} className='p-0'>
                    <Row className='time-week-container align-items-center px-0'>
                        <Col xs={12} md={5} lg={12} xxl={5} className='p-0' >
                            <div className='time-box-container justify-content-center'>
                                <div className='time-box-content'>
                                    <TimePicker
                                        onChange={(from) => handleSlotChange({ from }, slot.slotId)}
                                        allowDelete
                                        value={slot.from}
                                    />
                                </div>
                                <div className='time-box-content'>
                                    <TimePicker
                                        onChange={(to) => handleSlotChange({ to }, slot.slotId)}
                                        allowDelete
                                        value={slot.to}
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} md={7} lg={12} xxl={7} className="p-0">
                            <div className='week-day-checkbox-container'>
                                <span className='day-label-slot-width'>
                                    <Input name="Mon" checked={slot.days.Mon} onChange={() => handleSlotChange({ days: { ...slot.days, "Mon": !slot.days.Mon } }, slot.slotId)} type="checkbox" />
                                </span>
                                <span className='day-label-slot-width'>
                                    <Input name="Tues" checked={slot.days.Tues} onChange={() => handleSlotChange({ days: { ...slot.days, "Tues": !slot.days.Tues } }, slot.slotId)} type="checkbox" />
                                </span>
                                <span className='day-label-slot-width'>
                                    <Input name="Wed" checked={slot.days.Wed} onChange={() => handleSlotChange({ days: { ...slot.days, "Wed": !slot.days.Wed } }, slot.slotId)} type="checkbox" />
                                </span>
                                <span className='day-label-slot-width'>
                                    <Input name="Thurs" checked={slot.days.Thurs} onChange={() => handleSlotChange({ days: { ...slot.days, "Thurs": !slot.days.Thurs } }, slot.slotId)} type="checkbox" />
                                </span>
                                <span className='day-label-slot-width'>
                                    <Input name="Fri" checked={slot.days.Fri} onChange={() => handleSlotChange({ days: { ...slot.days, "Fri": !slot.days.Fri } }, slot.slotId)} type="checkbox" />
                                </span>
                                <span className='day-label-slot-width'>
                                    <Input name="Sat" checked={slot.days.Sat} onChange={() => handleSlotChange({ days: { ...slot.days, "Sat": !slot.days.Sat } }, slot.slotId)} type="checkbox" />
                                </span>
                                <span className='day-label-slot-width'>
                                    <Input name="Sun" checked={slot.days.Sun} onChange={() => handleSlotChange({ days: { ...slot.days, "Sun": !slot.days.Sun } }, slot.slotId)} type="checkbox" />
                                </span>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col xs={1}>
                    <img alt="close" className="btn-close-icon" src={images.closeIcon} onClick={() => handleDeleteSlot(slot.slotId)} />
                </Col>
            </Row>
        </div>
        // <tr>
        //     <th  className='time-slot'>
        //         <div className='time-box-container'>
        //             <div className='time-box-content'>
        //                 <TimePicker
        //                     onChange={(from) => handleSlotChange({ from }, slot.slotId)}
        //                     allowDelete
        //                     value={slot.from}
        //                 />
        //             </div>
        //             <div className='time-box-content'>
        //                 <TimePicker
        //                     onChange={(to) => handleSlotChange({ to }, slot.slotId)}
        //                     allowDelete
        //                     value={slot.to}
        //                 />
        //             </div>
        //         </div>
        //     </th>
        //     <td className='time-slot'>
        //     <Input name="Mon" checked={slot.days.Mon} onChange={() => handleSlotChange({ days: { ...slot.days, "Mon": !slot.days.Tues } }, slot.slotId)} type="checkbox" />
        //     </td>
        //     <td className='time-slot'>
        //     <Input name="Mon" checked={slot.days.Mon} onChange={() => handleSlotChange({ days: { ...slot.days, "Mon": !slot.days.Tues } }, slot.slotId)} type="checkbox" />
        //     </td>
        //     <td className='time-slot'>
        //     <Input name="Mon" checked={slot.days.Mon} onChange={() => handleSlotChange({ days: { ...slot.days, "Mon": !slot.days.Tues } }, slot.slotId)} type="checkbox" />
        //     </td>
        //     <td className='time-slot'>
        //     <Input name="Mon" checked={slot.days.Mon} onChange={() => handleSlotChange({ days: { ...slot.days, "Mon": !slot.days.Tues } }, slot.slotId)} type="checkbox" />
        //     </td>
        //     <td className='time-slot'>
        //     <Input name="Mon" checked={slot.days.Mon} onChange={() => handleSlotChange({ days: { ...slot.days, "Mon": !slot.days.Tues } }, slot.slotId)} type="checkbox" />
        //     </td>
        //     <td className='time-slot'>
        //     <Input name="Mon" checked={slot.days.Mon} onChange={() => handleSlotChange({ days: { ...slot.days, "Mon": !slot.days.Tues } }, slot.slotId)} type="checkbox" />
        //     </td>
        //     <td className='time-slot'>
        //     <Input name="Mon" checked={slot.days.Mon} onChange={() => handleSlotChange({ days: { ...slot.days, "Mon": !slot.days.Tues } }, slot.slotId)} type="checkbox" />
        //     </td>
        // </tr>
    )
}
