import React, { useEffect, useState } from "react";
import { Col, FormGroup, Input, Row, Spinner, Table } from "reactstrap";
import { getAvailability, updateAvailability } from "../../../apis/element";
import {
  calculateMinutes,
  images,
  toastError,
  toastSuccess,
  toastWarning,
} from "../../../utils/constants";
import CustomTimePicker from "../../CustomTimePicker/CustomTimePicker";
import "./AddTimeSlots.css";

export default function AddTimeSlots({
  isSmallScreen,
  getElementsHandler,
  handleNavigation,
  selectedAvailability,
}) {
  const [slots, setSlots] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isShowActionButton, setIsShowActionButton] = useState(false);
  const [availabilityName, setAvailabilityName] = useState("");
  const [participants, setParticipants] = useState([]);
  const [availabilityNameError, setAvailabilityNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [accessErrors, setAccessErrors] = useState({});

  useEffect(() => {
    if (selectedAvailability) {
      setAvailabilityName(selectedAvailability.name);
      setSlots(convertSlots(selectedAvailability.availabilitySlots));
      const participants = selectedAvailability.participants.map((email) => ({ email }));
      setParticipants([...participants, { email: "" }]);
    }
  }, [selectedAvailability]);

  const handleAddSlot = (e) => {
    e.preventDefault();
    setSlots((prev) => [
      ...prev,
      {
        slotId: Math.random(),
        from: "09:30",
        to: "12:00",
        days: {
          Mon: true,
          Tues: true,
          Wed: true,
          Thurs: true,
          Fri: true,
          Sat: false,
          Sun: false,
        },
      },
    ]);
    setIsShowActionButton(true);
  };

  const handleDeleteSlot = (slotId) => {
    setIsShowActionButton(true);
    const list = Object.assign(
      [],
      slots.filter((slot) => slot.slotId !== slotId)
    );
    setSlots(list);
  };

  const handleSlotChange = (data, slotId) => {
    const list = Object.assign([], slots);
    const index = list.findIndex((l) => l.slotId === slotId);
    Object.assign(list[index], data);
    setSlots(list);
    setIsShowActionButton(true);
  };

  function convertSlots(slots) {
    if (slots?.length) {
      const newSlots = slots.map((slot) => {
        const [Mon, Tues, Wed, Thurs, Fri, Sat, Sun] = ["Mon", "Tues", "Wed", "Thurs", "Fri", "Sat", "Sun"].map(day => slot.days.includes(day));
        return {
          slotId: slot.id,
          availabilityId: slot.id,
          createdAt: slot.createdAt,
          updatedAt: slot.updatedAt,
          days: {
            Mon: !!Mon,
            Tues: !!Tues,
            Wed: !!Wed,
            Thurs: !!Thurs,
            Fri: !!Fri,
            Sat: !!Sat,
            Sun: !!Sun,
          },
          from: slot.from,
          to: slot.to,
        };
      });
      return newSlots;
    }
    return [];
  }

  const addAvailabilitySlotsHandler = () => {
    const newSlots = slots.map((slot) => {
      const newDays = Object.keys(slot.days).reduce((p, key) => {
        if (slot.days[key] === true) return { ...p, [key]: slot.days[key] };
        else return p;
      }, {});
      return {
        slotId: slot?.availabilityId ? slot?.slotId : undefined,
        from: slot.from,
        to: slot.to,
        days: Object.keys(newDays).join(),
      };
    });

    const emailValidation = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    const isValidEmail = participants.reduce((prev, curr) => {
      if (curr.email && !emailValidation.test(curr.email)) {
        return { ...prev, [curr.email]: "Email is invalid !" };
      }
      return prev;
    }, null);

    if (!isValidEmail) {
      const newParticipants = participants.reduce((prev, curr) => {
        if (!!curr.email) return [...prev, curr.email];
        return prev;
      }, []);

      const data = {
        name: availabilityName,
        id: selectedAvailability?.id,
        elementId: selectedAvailability?.elementId,
        slots: newSlots,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        participants: newParticipants,
      };
      if (!newSlots.length) {
        toastWarning("Add time slots!", "top-right");
      } else if (!newSlots.every((a) => a.days)) {
        toastWarning("Days must be selected!", "top-right");
      } else if (!calculateMinutes(newSlots)) {
        toastWarning("Duration should be more than 30 minutes!", "top-right");
      } else if (!availabilityName) {
        setAvailabilityNameError("Availability name required!");
      } else {
        setLoading(true);
        updateAvailability(selectedAvailability?.id, data)
          .then((res) => {
            getElementsHandler(selectedAvailability.elementId, "availability");
            // getAvailabilityHandler();
            setIsShowActionButton(false);
            setLoading(false);
            toastSuccess("", isSmallScreen ? "bottom-right" : "top-right");
          })
          .catch((err) => {
            if (err.response?.data?.message === "Permission Error") {
              const accessErrorMessage =
                "You do not have access to this calendar, please request access to this calendar.";
              const error = err.response.data.data.reduce(
                (prev, curr) => ({ ...prev, [curr]: accessErrorMessage }),
                {}
              );
              setAccessErrors(error);
            } else {
              toastError(err.response?.data?.message, "top-right");
            }
            setLoading(false);
          });
      }
    } else setEmailError(isValidEmail);
  };

  const handleCancelAvailabilitySlots = () => {
    setIsShowActionButton(false);
  };

  const handleAvailabilityNameChange = (name) => {
    setIsShowActionButton(true);
    setAvailabilityNameError("");
    setAvailabilityName(name);
  };

  const handleChange = (e, i) => {
    setEmailError("");
    setAccessErrors("");
    const newParticipants = Object.assign([], participants);
    newParticipants[i] = { email: e.target.value };
    const isEmailEmpty = newParticipants.find((p) => !p.email);
    if (!isEmailEmpty) {
      newParticipants.push({ email: "" });
    }
    setParticipants(newParticipants);
  };

  const handleRemoveParticipate = (i) => {
    setEmailError("");
    setAccessErrors("");
    const newParticipants = Object.assign([], participants);
    newParticipants.splice(i, 1);
    setParticipants(newParticipants);
  };

  return (
    <section className="d-flex flex-column justify-content-between time-slot-container">
      <div className="mb-3">
        <div className="calendar-main-title-container">
          <div
            onClick={() => handleNavigation("2", "left", "/availability")}
            className="back-button"
          >
            <img src={images.backIcon} alt="icon" />
            <label className="calendar-header">Availability options</label>
          </div>
          <h1 className="calendar-main-title">Time Slots</h1>
        </div>

        <FormGroup className="mb-0">
          <h6 className="availability-name-label">Availability name</h6>
          <Input
            className="txt-availability-name"
            type="text"
            name="name"
            placeholder="Enter availability name"
            value={availabilityName}
            disabled={selectedAvailability?.name === "Default Availability"}
            onChange={({ target }) =>
              handleAvailabilityNameChange(target.value)
            }
          />
          <p className="formik-error mb-0">{availabilityNameError}</p>
        </FormGroup>
        {!!slots.length && (
          <Row className="m-0">
            <Col xs={11} className="p-0">
              <Row className="m-0 py-1">
                <Col xs={12} md={5} lg={12} xxl={5} className="p-0" />
                <Col xs={12} md={7} lg={12} xxl={7} className="p-0">
                  <div className="week-days-container d-flex justify-content-between">
                    <label className="day-label-slot-width">Mon</label>
                    <label className="day-label-slot-width">Tue</label>
                    <label className="day-label-slot-width">Wed</label>
                    <label className="day-label-slot-width">Thu</label>
                    <label className="day-label-slot-width">Fri</label>
                    <label className="day-label-slot-width">Sat</label>
                    <label className="day-label-slot-width">Sun</label>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        {!slots.length ? (
          <div className="d-flex justify-content-center">
            <p>Please add time slot</p>
          </div>
        ) : (
          slots.map((slot) => {
            return (
              <CustomTimePicker
                key={slot.slotId}
                handleDeleteSlot={() => handleDeleteSlot(slot.slotId)}
                slot={slot}
                handleSlotChange={handleSlotChange}
              />
            );
          })
        )}
        <div className="mt-3">
          <a className="btn-add-slot" href="" onClick={(e) => handleAddSlot(e)}>
            Add Time Slot
          </a>
        </div>

        <p className="availability-name-label mb-0 mt-3">
          [Optional] Calendars used to calculate availability
        </p>
        <p className="availability-info-text mt-2">
          The below calendars will be taken into account only to calculate
          availability. If you want them be sent a calendar invitation,
          please instead add them as participants in a Team
        </p>
        <div className="availability-email-container">
          {participants.length &&
            participants.map((participant, i, arr) => (
              <FormGroup key={i} className="email-content d-flex flex-column">
                <div className="d-flex">
                  <Input
                    className="txt-availability-email"
                    type="email"
                    name="email"
                    placeholder="Enter email"
                    value={participant.email}
                    onChange={(e) => handleChange(e, i)}
                  />
                  {i !== 0 && arr.length - 1 !== i && (
                    <img
                      alt="close"
                      className="btn-close-icon"
                      src={images.closeIcon}
                      onClick={() => handleRemoveParticipate(i)}
                    />
                  )}
                </div>
                <p className="formik-error mt-1 mb-0">
                  {emailError[participant.email] ||
                    accessErrors[participant.email]}
                </p>
              </FormGroup>
            ))}
        </div>
      </div>
      {isShowActionButton && (
        <div className="time-slot-button-container">
          <button
            className="btn-cancel"
            onClick={handleCancelAvailabilitySlots}
          >
            Cancel
          </button>
          <button className="btn-save" onClick={addAvailabilitySlotsHandler}>
            {isLoading ? <Spinner size="sm" /> : "Save"}
          </button>
        </div>
      )}
    </section>
  );
}
