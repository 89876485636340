export const BASE_URL = process.env.REACT_APP_API_URL;
export const URLS = {
  GOOGLE_AUTH: BASE_URL + '/authentication/google/token',
  ZOOM_INTEGRATION: BASE_URL + '/authentication/integrations/zoom',
  CALENDARS: BASE_URL + '/calendars',
  ELEMENT: BASE_URL + '/elements',
  UPDATE_USER: BASE_URL + '/users/update',
  PUBLIC_CALENDAR: BASE_URL + '/calendars/getPublicCalendar',
  PUBLIC_CALENDAR_SLOTS: BASE_URL + '/calendars/slots',
  PUBLIC_CALENDAR_EVENT: BASE_URL + '/calendars/events',
  AVAILABILITY: BASE_URL + '/availability',

  TEAM: BASE_URL + '/teams',
  GET_TIMEZON: BASE_URL + '/calendars/timezones',
  SUPPORT: BASE_URL + '/users/support-request',
  GET_VERSION: BASE_URL + '/version',
}
