import React, { useState } from 'react'
import { Spinner } from 'reactstrap';
import { images,toastError,toastSuccess } from "../../../utils/constants";
import "./SelectTeam.css";
import { useLocation } from 'react-router-dom';
import {deleteElement} from "../../../apis/element";

export default function SelectTeam({isSmallScreen, handleNavigation, selectedTeam,getElementsHandler }) {
    const { pathname } = useLocation();
    const path = pathname.split("/");
    const [isLoadingDelete, setLoadingDelete] = useState(false);

    const deleteTeamHandler = () => {
        setLoadingDelete(true);
        deleteElement(selectedTeam?.elementId)
            .then(() => {
                getElementsHandler(null,"",false,true);
                setLoadingDelete(false);
                toastSuccess("", isSmallScreen ? "bottom-right" : "top-right");
                handleNavigation("1", "right", "/");
            }).catch(err => {
                setLoadingDelete(false);
                toastError(err.response?.data?.message, "top-right");
            })
    }

    return (
       <div className='flex-grow-1 d-flex flex-column justify-content-between'>
                <div>
                    <div className='calendar-main-title-container'>
                        <div className="back-button" onClick={() => handleNavigation("1", "right", "/")}>
                            <img src={images.backIcon} alt="icon" />
                            <label className="calendar-header">Home</label>
                        </div>
                        <h1 className="calendar-main-title">{selectedTeam?.name}</h1>
                    </div>
                    <div className='team-container mb-3'>
                        <div className={`team-item ${path[2] === "teamdetails" ? "selected" : ""}`}
                            onClick={() => handleNavigation("3", "left", "/team/teamdetails")}>
                            <div className='team-icon icon-container'>
                                <img src={images.teamIcon} alt="team-icon" />
                            </div>
                            <div className="team-title-container border-0">
                                <h6 className="team-item-title mb-0">
                                    Team
                                </h6>
                                <div className="direction-right pe-3">
                                    <img src={images.angleRightIcon} alt="angle_right" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               <div className='d-flex justify-content-center'>
                    <button className="btn-delete-availability" onClick={() => deleteTeamHandler()}>
                        {isLoadingDelete ? <Spinner size={"sm"} color='primary' /> : "Delete Team"}
                    </button>
                </div>
            </div>
    )
}
