import React, { useEffect, useState } from 'react';
import { Col, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, FormFeedback, Input, Row, Spinner, Tooltip } from 'reactstrap';
import "./SetURL.css";
import { images, toastError, toastSuccess } from "../../utils/constants"
import { updateUser } from "../../apis/user";
import { createElement } from '../../apis/element';
export default function SetURL({ isSmallScreen, userLogin, handleNavigation, setUserLogin }) {
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState({ ...userLogin, link: userLogin.link ? userLogin.link : userLogin.email && userLogin.email.split("@")[0] });
  const [locationLink, setLocationLink] = useState('');
  const [locationLinkError, setLocationLinkError] = useState('');
  const [defaultLocation, setDefaultLocation] = useState("Google Meet");
  const [isChecked, setIsChecked] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [error, setError] = useState("");
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const location = [
    "Google Meet",
    "Physical address",
    "Video conferencing link",
    "Other",
  ];
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => {
    setTooltipOpen(!tooltipOpen);
  }

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  }


  const locationChangeHandler = (location) => {
    if (location === 'Google Meet') {
      setLocationLink('')
    }
    setLocationLinkError("");
    setDefaultLocation(location)
  }


  useEffect(() => {
    if (!user) {
      setUser(JSON.parse(localStorage.getItem('user')));
    }
  }, [])

  const updatePersonalLinkHandler = async () => {
    if (defaultLocation !== "Google Meet" && !locationLink) {
      setLocationLinkError("Location is required");
    } else {
    if (user.link) {
      const isLinkValid = /^[a-zA-Z0-9 -]+$/.test(user.link);
      const location = locationLink ? locationLink : defaultLocation
      if (isLinkValid) {
        let link = user.link.split(" ").join("-");
        try {
          setIsLoading(true);
          const data = { access_token: user.accessToken, agreeToTermsAndPolicy: true, defaultLocation: location };
          if (!userLogin.link) data.link = link;
          await updateUser(data);
          setUserLogin({ ...user, ...data });
          localStorage.setItem('user', JSON.stringify({ ...user, ...data }));
          if (link)
            handleNavigation("1", "left", "/");
          toastSuccess("", isSmallScreen ? "bottom-right" : "top-right");
          setIsLoading(false);
          if (defaultLocation !== "Google Meet") {
            let payload = {
              type: "location",
              timezone,
              name: locationLink
            }
            createElement(payload)
              .then(({ data }) => {
                toastSuccess("", isSmallScreen ? "bottom-right" : "top-right");
                setIsLoading(false);
              }).catch(err => {
                setIsLoading(false);
                toastError(err.response?.data?.message, "top-right");
              })
          }
        } catch (err) {
          setError(err.response?.data.message);
          setIsLoading(false);
        }

      } else setError("You are trying to use characters not compatible with a URL");
    } else setError("URL Required");
  }
}

  return (
    <Container className="set-url-container">
      <Row className='px-3'>
        <Col className="set-url-card" xs={12} xl={5} sm={10} lg={6}>
          <div className='mb-3'>
            <img className="set-url-brand-logo" alt="brand_logo" src={images.brandLogo} />
          </div>
          <h1 className='we-cal-title'>Welcome</h1>
          <span className='we-cal-desc'>Great scheduling. For Free.</span>
          <label className="signup-label">YOUR WECAL URL</label>
          <div className='d-flex align-items-center w-100 mb-3'>
            <span className='url'>wecal.co/</span>
            <Input
              className="input-url-link mb-0 ml-1"
              type="text"
              name="link"
              onChange={e => {
                setUser(a => ({ ...a, link: e.target.value }));
                setError('');
              }}
              value={user?.link}
              placeholder="Enter calendar link" />
          </div>
          <p className='update-modal-link-error text-danger mb-2'>{error}</p>
          <label className="signup-label mb-3">YOUR DEFAULT LOCATION</label>
          <div className='d-flex w-100 align-items-center'>
            <Dropdown className='onBoarding-dropdown w-100' isOpen={dropdownOpen} toggle={toggleDropdown}>
              <DropdownToggle className='d-flex justify-content-between ps-1' tag="div">
                <span>{defaultLocation}</span>
                <div className="dropdown-icon-container-onBoarding">
                  <img className="dropdown-icon" src={images.dropdownIcon} alt="icon" />
                </div>
              </DropdownToggle>
              <DropdownMenu>
                {location.map(location => (
                  <DropdownItem key={location.id} onClick={() => locationChangeHandler(location)}>
                    {location}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
            <img src={images.infoIcon} alt="infoIcon" className='info-icon' id="onBoardingInfo" />
            <Tooltip className='meeting-info-tooltip' placement="bottom"
              isOpen={tooltipOpen} target="onBoardingInfo" toggle={toggle}>
              The location specified here will be <br /> used as default location In WeCal
            </Tooltip>
          </div>
          {defaultLocation !== "Google Meet" && <>
            <div className='mt-4 w-100'>
              <Input
                className="input-url-link mb-0 ml-1"
                type="text" name="link"
                onChange={e => {
                  setLocationLink(e.target.value);
                  setError('');
                }}
                value={locationLink}
                placeholder="Add the details here" />
            </div>
              <p className='update-modal-link-error text-danger mb-0 mt-1'>{locationLinkError}</p>
              </>
          }
          <label className="signup-label mt-4 mb-1">YOUR AVAILABILITIES</label>
          <div className='d-flex w-100 align-items-center'>
              <p className='availability-text m-0'>Your default availability will be <span className="bold">09:30 12:00</span> and <span className='bold'>14:00 17:00</span>.
              You can change this and also create additional availabilities and different types of meetings in the application.
              </p>
          </div>
          <div className='d-flex mt-5'>
            <div className='d-flex'>
              <Input name="accept" checked={isChecked} onChange={() => setIsChecked(!isChecked)} type="checkbox" />
            </div>
            <span className='signup-terms'>I have read and I accept the {' '}
              <a href="https://www.wecal.co/index.php/terms-and-conditions/">Terms & Conditions</a> and the {' '}
              <a href="https://www.wecal.co/index.php/privacy-policy/">
                Privacy Policy</a></span>
          </div>
          <div className="onBoarding-button-container mt-4 w-100">
            <button type="button" className='btn-save' onClick={updatePersonalLinkHandler} disabled={!isChecked}>
              {isLoading ? <Spinner size="sm" color="primary" /> : "Save & Next"}
            </button>
          </div>
        </Col>
      </Row>
    </Container>
  )
}
