import React, { useEffect, useRef, useState } from 'react'
import { Button, FormGroup, Input, Label, Spinner } from 'reactstrap'
import "./LocationDetails.css"
import { images, toastError, toastSuccess } from "../../../utils/constants";
import {updateElement} from "../../../apis/element";
import {Redirect} from "react-router-dom";

export default function LocationDetails({userLogin,isSmallScreen,  getElementsHandler, handleNavigation, selectedElement }) {
  // const [locationDetail, setLocationDetail] = useState(selectedElement);
  const [locationNameError, setLocationNameError] = useState("");
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
  const { id } = selectedElement || {};
  const [locationName,setLocationName] = useState(selectedElement?.name);
  // useEffect(() => {
  //   if (selectedElement) {
  //     setLocationDetail(selectedElement);
  //   }
  // }, [selectedElement])

  const handleLocationDetails = () => {
    if (locationName) {
        setIsLoadingUpdate(true);
        const payload = {
          type: "location",
          name:locationName,
        }
        updateElement(id, payload).then(() => {
          getElementsHandler(id);
          setIsLoadingUpdate(false);
          toastSuccess("", isSmallScreen ? "bottom-right" : "top-right");
        }).catch(err => {
          setIsLoadingUpdate(false);
            toastError(err.response?.data?.message, "top-right");
        })
      }
  }

  const handleCancelUpdate = () => {
    setLocationName(selectedElement?.name);
  }

  const handleLocationNameChange = (name) => {
    setLocationNameError("");
    setLocationName(name);
    // setLocationDetail(p => ({ ...p, name }));
  }
  if (!selectedElement)return <Redirect to="/" />
  return (
      <div className='location-details-container d-flex flex-column justify-content-between'>
        <div>
          <div className='calendar-main-title-container'>
            <div className="back-button" onClick={() => handleNavigation("2", "left", "/location")}>
              <img src={images.backIcon} alt="icon" />
              <label className="calendar-header">Location</label>
            </div>
            <h1 className="calendar-main-title">Location</h1>
          </div>
          <FormGroup className='mb-0'>
            <h6 className='location-name-label'>Location</h6>
            <Input
              className='txt-location-name'
              type="text" name="locationName"
              placeholder="Please insert here an address or the location information"
              value={locationName}
              onChange={({ target }) => handleLocationNameChange(target.value)}
            />
            <p className="formik-error mb-0">{locationNameError}</p>
          </FormGroup>
        </div>
        {locationName !== selectedElement?.name && <div className='location-confirmation-container'>
          <div className="location-button-container">
            <button type="button" className='btn-cancel' onClick={handleCancelUpdate}>Cancel</button>
            <button type="button" disabled={!locationName} className='btn-save' onClick={handleLocationDetails}>
              {isLoadingUpdate ? <Spinner size="sm" color="primary"/> : "Save"}
            </button>
          </div>
        </div>}
      </div>
  )
}
