import "./UserDetails.css";
import { images } from "../../../utils/constants";
import { CLIENT_ID } from "../../../calendarConfig";
import { useGoogleLogout } from 'react-google-login';
import { useLocation } from 'react-router-dom';
export default function UserDetails({ handleNavigation, userLogin, setUserLogin }) {
  const { pathname } = useLocation();
  const path = pathname.split("/");

  const handleSignOut = () => {
    localStorage.clear();
    setUserLogin(null);
  };
  const { signOut } = useGoogleLogout({ clientId: CLIENT_ID, onLogoutSuccess: handleSignOut })

  return (
    <section className="profile-details-container">
      <div>
        <div className='calendar-main-title-container'>
          <div className="back-button" onClick={() => handleNavigation("1", "right", "/")}>
            <img src={images.backIcon} alt="icon" />
            <label className="calendar-header">Home</label>
          </div>
          <h1 className="calendar-main-title">{userLogin?.name}</h1>
        </div>
        <div className={`profile-details mb-3 p-3 ${path.length === 2 ? "selected" : ""}`}>
          <div onClick={() => handleNavigation("3", "left", "/profiledetails")} className="profile-details-item">
            <div className='profile-details-icon icon-container'>
              <img src={images.chainIcon} alt=".." />
            </div>
            <div className="profile-details-title-container border-0">
              <h6 className="personal-link-title mb-0">
                Personal link
              </h6>
              <div className='main-calendar-link-container d-flex align-items-center justify-content-end'>
                <p className="text-truncate main-calendar-link mb-0 mx-1">{`/${userLogin?.link}`}</p>
                <div className="direction-right">
                  <img src={images.angleRightIcon} alt="right_angle_icon" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={`profile-details mb-3 p-3 ${path[2] === "defaultLocation" ? "selected" : ""}`}>
          <div onClick={() => handleNavigation("3", "left", '/profiledetails/defaultLocation')} className="profile-details-item">
            <div className='profile-details-icon icon-container'>
              <img src={images.physicalMeetingIcon} alt=".." />
            </div>
            <div className="profile-details-title-container border-0">
              <h6 className="personal-link-title mb-0">
                Default Location
              </h6>
              <div className='main-calendar-link-container d-flex align-items-center justify-content-end'>
                <div className="direction-right">
                  <img src={images.angleRightIcon} alt="right_angle_icon" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={`profile-details mb-3 p-3 ${path[2] === "support" ? "selected" : ""}`}>
          <div onClick={() => handleNavigation("3", "left", '/profiledetails/support')} className="profile-details-item">
            <div className='profile-details-icon icon-container'>
              <img src={images.SupportIcon} alt=".." />
            </div>
            <div className="profile-details-title-container border-0">
              <h6 className="personal-link-title mb-0">
                Support
              </h6>
              <div className='main-calendar-link-container d-flex align-items-center justify-content-end'>
                <div className="direction-right">
                  <img src={images.angleRightIcon} alt="right_angle_icon" />
                </div>
              </div>
            </div>
          </div>
        </div>

      
        {/* <div className='profile-details mb-3 p-3'>
            <label className="time-zone-title">Time zone: </label> <label>{userLogin?.timezone}</label>
          </div> */}
      </div>
      <div className='d-flex justify-content-center'>
        <button className="button-logout-user" onClick={signOut}>
          Logout
        </button>
      </div>
    </section>
  )
}
