import React, { useState } from 'react'
import "./SelectAvailabilityComponent.css";
import { images, toastError,toastSuccess } from "../../../utils/constants";
import { deleteElement } from '../../../apis/element';
import { Spinner } from 'reactstrap';
import { useLocation } from 'react-router-dom';
export default function SelectAvailabilityComponent({isSmallScreen, handleNavigation, selectedAvailability, getElementsHandler }) {
    const [isLoading, setLoading] = useState(false);
    const { pathname } = useLocation();
    const path = pathname.split("/");
    const deleteAvailabilityHandler = () => {
        setLoading(true);
        deleteElement(selectedAvailability?.elementId)
            .then(() => {
                setLoading(false)
                getElementsHandler(null, "", true);
                toastSuccess("", isSmallScreen ? "bottom-right" : "top-right");
                handleNavigation("1", "right", "/");
            }).catch(err => {
                setLoading(false);
                toastError(err.response?.data?.message, "top-right");
            })
    }

    return (
        <div className="calendar-details-container">
            <div>
                <div className='calendar-main-title-container'>
                    <div className="back-button" onClick={() => handleNavigation("1", "right", "/")}>
                        <img src={images.backIcon} alt="icon" />
                        <label className="calendar-header">Home</label>
                    </div>
                    <h1 className="calendar-main-title">{selectedAvailability?.name}</h1>
                </div>
                <div className="availability-container mb-3">
                    <div className={`availability-item ${path[2] === "timeslots" ? "selected" : ""}`}
                        onClick={() => handleNavigation("3", "left", "/availability/timeslots")}>
                        <div className='watch-icon icon-container'>
                            <img src={images.watchIcon} alt="..." />
                        </div>
                        <div className="availability-title-container border-0">
                            <h6 className="availability-item-title mb-0">
                                Time slots
                            </h6>
                            <div className="direction-right pe-3">
                                <img src={images.angleRightIcon} alt="angle_right" />
                            </div>
                        </div>
                    </div>
                </div>
                <label className='coming-soon-title mb-1'>Coming soon</label>
                <div className='availability-container mb-3 '>
                    <div className="availability-item">
                        <div className='setting-icon icon-container'>
                            <img src={images.settingIcon} alt="..." />
                        </div>
                        <div className="availability-title-container border-0">
                            <h6 className="availability-item-title mb-0">
                                Other settings
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
            {selectedAvailability?.name !== "Default Availability" && <div className='d-flex justify-content-center'>
                <button className="btn-delete-availability" onClick={deleteAvailabilityHandler}>
                    {isLoading ? <Spinner size="sm" color='primary' /> : "Delete Availability"}
                </button>
            </div>}
        </div>
    )
}