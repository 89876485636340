import React from 'react';
import moment from "moment";
export default function ConfirmedMeeting({setIsConfirmedRequest,handleNavigation,bookedEvent,publicCalendar}) {

    const handleGoBack = () => {
        handleNavigation("2", "left");
        setIsConfirmedRequest(false);
    }

    return (
        <section className='confiremed-meeting-section'>
            <h1 className="calendar-main-title mb-4">Meeting Confirmed</h1>
            <span className='event-message col'>
                You are scheduled for
                <span className='mx-1'>{moment(bookedEvent.date).format("dddd DD MMMM YYYY")}</span>
                <span>{new Date(bookedEvent.slot[0]).toLocaleTimeString('en', { timeStyle: 'short', hour12: false })} -
                    {moment(new Date(bookedEvent.slot[0])).add(+publicCalendar?.duration, 'm').format('HH:mm')}
                </span>
                . A calendar invitation has been sent to your email address.
            </span>
            <div className="add-email-button-container">
                <button className='btn-save' onClick={handleGoBack} >
                    Go back
                </button>
            </div>
        </section>
    )
}
