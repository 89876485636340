import React, { useEffect, useState } from 'react'
import { ListGroupItem, Spinner, Tooltip } from 'reactstrap'
import "./SelectVideoMeeting.css"
import { images, toastError } from "../../../utils/constants";
// import { ZOOM_CLIENT_ID } from "../../../calendarConfig";
// import { updateZoomIntegration } from "../../../apis/auth";
export default function SelectVideoMeeting({ handleNavigation, selectedElement, showPanel, setZoomEnabled, zoomEnabled, zoomAccessCode }) {
    const [googleMeeting, setGoogleMeeting] = useState(true);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    // const [stopUpdateZoomAccess, setStopUpdateZoomAccess] = useState(true);
    // const [ischangeZoomAccess, setIsChangeZoomAccess] = useState(false);
    // const [isLoading, setIsLoading] = useState(false);
    // useEffect(() => {
    //     if (zoomAccessCode || (zoomEnabled && !stopUpdateZoomAccess)) {
    //         setIsLoading(true);
    //         let payload = { code: zoomAccessCode, enabled: true };
    //         if (zoomEnabled) {
    //             payload = { enabled: false };
    //         }
    //         updateZoomIntegration(payload)
    //             .then(() => {
    //                 setIsLoading(false);
    //                 setZoomEnabled(!zoomEnabled);
    //                 handleNavigation("2", "", "/elementdetails");
    //             }).catch(err => {
    //                 setIsLoading(false);
    //                 toastError(err.response?.data?.message, "top-right");
    //             })
    //     }
    // }, [ischangeZoomAccess]);

    const toggle = () => {
        setTooltipOpen(!tooltipOpen);
    }


    // const accessZoomHandleChange = () => {
    //     if (!zoomEnabled) {
    //         setIsLoading(true);
    //         const domain = window.location.host;
    //         window.location.replace(`https://zoom.us/oauth/authorize?response_type=code&client_id=${ZOOM_CLIENT_ID}&redirect_uri=http://${domain}/elementdetails`);
    //     }
    //     setStopUpdateZoomAccess(false);
    //     setIsChangeZoomAccess(!ischangeZoomAccess);
    // }
    return (
        <>
            <div className="calendar-main-title-container">
                <div className="back-button" onClick={() => handleNavigation(showPanel === "2" ? "1" : "2", showPanel === "2" ? "right" : "left", showPanel === "2" ? "/" : "/newelement")}>
                    <img src={images.backIcon} alt="icon" />
                    <label className="calendar-header">Home</label>
                </div>
                <h1 className="calendar-main-title">{selectedElement?.name}</h1>
            </div>
            <ListGroupItem className="switch-container border-0 mb-3">
                <span className="switch-label" htmlFor="googleMeetSwitch">Google Meet</span>
                <div className="form-check form-switch">
                    <input
                        onChange={() => setGoogleMeeting(true)}
                        className="form-check-input m-0"
                        type="checkbox"
                        role="switch"
                        id="googleMeetSwitch" checked={googleMeeting} />
                </div>

            </ListGroupItem>
            {/* <ListGroupItem className="switch-container border-0 mb-3">
                <span className="switch-label" htmlFor="googleMeetSwitch">Zoom Meet</span>
                <div className="form-check form-switch">
                    {isLoading ? <Spinner color="primary" size="sm" /> :
                        <input
                            onChange={accessZoomHandleChange}
                            className="form-check-input m-0"
                            type="checkbox"
                            role="switch"
                            id="googleMeetSwitch" checked={zoomEnabled} />
                    }

                </div>
            </ListGroupItem> */}

            <label className='coming-soon-title mb-1'>Coming soon</label>
            <div className='coming-soon-container py-1'>
                <div className='coming-soon-item border-bottom '>
                    <div className="coming-soon-title-container border-0">
                        <h6 className="title mb-0">
                            Zoom (native integration)
                            <img src={images.infoIcon} className="mx-1" alt="info_icon" id="robinMeeting" />
                            <Tooltip className='meeting-info-tooltip' placement="bottom"
                                isOpen={tooltipOpen} target="robinMeeting" toggle={toggle}>
                                For now you can add your Personal Meeting ID (PMI) and
                                passwords to a location object and use it on an event.
                            </Tooltip>
                        </h6>
                    </div>
                </div>
                <div className='coming-soon-item border-bottom '>
                    <div className="coming-soon-title-container border-0">
                        <h6 className="title mb-0">
                            Google Sheets
                        </h6>
                    </div>
                </div>
                <div className='coming-soon-item border-bottom'>
                    <div className="coming-soon-title-container border-0">
                        <h6 className="title mb-0">
                            Slack
                        </h6>
                    </div>
                </div>
                <div className='coming-soon-item border-bottom'>
                    <div className="coming-soon-title-container border-0">
                        <h6 className="title mb-0">
                            Hubspot
                        </h6>
                    </div>
                </div>
                <div className='coming-soon-item border-bottom'>
                    <div className="coming-soon-title-container border-0">
                        <h6 className="title mb-0">
                            Zapier
                        </h6>
                    </div>
                </div>
                <div className='coming-soon-item border-bottom'>
                    <div className="coming-soon-title-container border-0">
                        <h6 className="title mb-0">
                            Mailchimp
                        </h6>
                    </div>
                </div>
                <div className='coming-soon-item'>
                    <div className="coming-soon-title-container border-0">
                        <h6 className="title mb-0">
                            Microsoft Teams
                        </h6>
                    </div>
                </div>
            </div>
        </>
    )
}
