import React from 'react'
import { images } from '../../utils/constants';
import "./SplashScreen.css"

export default function SplashScreen({reload,message}) {
  const reloadPage = ()=>{
    window.location.reload(true);
  }
  return (
    <div className="splash-page">
    <div>
      <img alt="logo" src={images.brandLogo} />
      {!!message && <p className='mt-1'>{message}</p>}
      {!!reload && <button onClick={reloadPage} className='mt-1'>Reload</button>}
    </div>
  </div>
  )
}
