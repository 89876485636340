import React from 'react'
import { images } from '../../../utils/constants';
import "./PublicPageFreeSlots.css";
import moment from "moment";
import { Spinner } from 'reactstrap';
export default function PublicPageFreeSlots({ bookingEnabled, freeSlotsLoading, setSelectedEvent, selectedEvent, handleNavigation, freeSlots, selectedTimeZone }) {
    const handleSelectEvent = (slot) => {
        if (bookingEnabled) {
            setSelectedEvent(prev => ({ ...prev, slot }))
            handleNavigation("3", "left");
        }
    }

    const handleGoBack = () => {
        handleNavigation("1", "right");
        setSelectedEvent(prev => ({ ...prev, slot: null }))
    }

    return (
        <>
            <div className="back-button"
                onClick={handleGoBack}>
                <img src={images.backIcon} alt="back_icon" />
                <label className="calendar-header">Calendar</label>
            </div>
            {selectedEvent.date &&
                <h1 className="calendar-main-title mb-3">{moment(selectedEvent.date).format("dddd DD MMMM YYYY")}</h1>}
            {freeSlotsLoading ? <div className='d-flex justify-content-center bg-light p-2'>
                <Spinner color="primary" />
            </div> :
                !freeSlots.length ? <div className='d-flex justify-content-center'><p>No freeslots </p></div> :
                    <div className='event-time-container mb-3 py-1 ps-3'>
                        {freeSlots.map((slot, i) => {
                            const startTime = new Date(slot[0]).toLocaleTimeString('en', { timeStyle: 'short', hour12: false, timeZone: selectedTimeZone });
                            return (
                                <div key={slot.date} onClick={() => handleSelectEvent(slot)}
                                    className={`event-time-item ${!bookingEnabled ? "disbled-slots" : ""}`}>
                                    <div className={`event-time-title-container ${(freeSlots.length - 1) === i ? "border-0" : ""}`}>
                                        <h6 className="event-item-title mb-0">{startTime}</h6>
                                        {bookingEnabled && <div className="direction-right pe-3">
                                            <img src={images.angleRightIcon} alt="angle_right" />
                                        </div>}
                                    </div>
                                </div>)
                        })}
                    </div>
            }
        </>
    )
}
