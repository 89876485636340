import React, { useEffect } from 'react'
import { useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Spinner } from 'reactstrap'
import { updateCalendar } from '../../../apis/calendars';
import { getElements } from '../../../apis/element';
import { updateUser } from '../../../apis/user';
import { images } from '../../../utils/constants';

export default function DefaultLocation({showPanel, userLogin,handleNavigation, setUserLogin}) {
  const [location, setLocation] = useState([])
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [defaultLocation, setDefaultLocation] = useState({ id: "1", name: "Google Meet" });
  const [isEditable, setEditable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getElements("location").then(res => setLocation([{ id: "1", name: "Google Meet" }, ...res.data]));
  }, [])

  const toggleDropdown = () => {
    if (isEditable) setDropdownOpen(!dropdownOpen);
    else setEditable(true);
  }
  const locationChangeHandler = (location) => {
    setDefaultLocation({ location })
  }

  useEffect(() => {
    setDefaultLocation({ location: userLogin.defaultLocation })
  }, [userLogin])

  const updateMeetingCalendar = () => {
    setIsLoading(true);
    updateUser({ defaultLocation: defaultLocation.location })
      .then((res) => {
        const updatedUser = { ...userLogin, defaultLocation: defaultLocation.location }
        setUserLogin(updatedUser);
        localStorage.setItem('user', JSON.stringify(updatedUser));
        setEditable(!isEditable);
        setIsLoading(false);
      }).catch(err => {
        console.log(err, "err")
        setIsLoading(false);
      })
  }

  const editableHandler = () => {
    setEditable(!isEditable)
  }

  return (
    <div className='d-flex flex-column h-100 justify-content-between'>
      <div>
        <div className="calendar-main-title-container">
          <div className="back-button"
            onClick={() => handleNavigation(showPanel === "2" ? "1" : "2", showPanel === "2" ? "right" : "left", showPanel === "2" ? "/" : "/profiledetails")}>
            <img src={images.backIcon} alt="icon" />
            <label className="calendar-header">Profile details</label>
          </div>
          <h1 className="calendar-main-title">Default Location</h1>
        </div>
        <div className="main-calendar-link-container me-1 d-flex">
          <Dropdown className='duration-dropdown w-100' isOpen={dropdownOpen && isEditable} toggle={toggleDropdown}>
            <DropdownToggle className='d-flex justify-content-between ps-2' tag="div">
              <span>{defaultLocation.location}</span>
              <div className="dropdown-icon-container">
                <img className="dropdown-icon" src={images.dropdownIcon} alt="icon" />
              </div>
            </DropdownToggle>
            <DropdownMenu>
              {location.map(location => (
                <DropdownItem key={location.id} onClick={() => locationChangeHandler(location.name)}>
                  {location.name}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
      {isEditable && <div className="editable-button-container">
        <button type="button" className='btn-cancel' onClick={editableHandler}>Cancel
        </button>
        <button type="button" className='btn-save' onClick={updateMeetingCalendar} >
          {isLoading ? <Spinner size="sm" color="primary" /> : "Save"}
        </button>
      </div>}
    </div>
  )
}
