import { toast } from "react-toastify";

export const images = {
    brandLogo: process.env.PUBLIC_URL + "/brand-logo.svg",
    chainIcon: process.env.PUBLIC_URL + "/assets/chain-icon.svg",
    angleRightIcon: process.env.PUBLIC_URL + "/assets/angle-right-icon.svg",
    backIcon: process.env.PUBLIC_URL + "/assets/angle-left.svg",
    googleIcon: process.env.PUBLIC_URL + "/assets/google-icon.png",
    searchIcon: process.env.PUBLIC_URL + "/assets/search-icon.svg",
    videoMeetingIcon: process.env.PUBLIC_URL + "/assets/video-meeting-icon.svg",
    addIcon: process.env.PUBLIC_URL + "/assets/new-add-icon.svg",
    calendarIcon: process.env.PUBLIC_URL + "/assets/calendar-icon.svg",
    collectiveIcon: process.env.PUBLIC_URL + "/assets/collective-icon.svg",
    availabilityIcon: process.env.PUBLIC_URL + "/assets/availability.svg",
    meetingIcon: process.env.PUBLIC_URL + "/assets/meeting-icon.svg",
    infoIcon: process.env.PUBLIC_URL + "/assets/info-icon.svg",
    roundMeetingIcon: process.env.PUBLIC_URL + "/assets/round-meeting.svg",
    physicalMeetingIcon: process.env.PUBLIC_URL + "/assets/physical-meeting.svg",
    emailIcon: process.env.PUBLIC_URL + "/assets/email-icon.svg",
    checkedIcon: process.env.PUBLIC_URL + "/assets/checked-icon.svg",
    bookingIcon: process.env.PUBLIC_URL + "/assets/booking-icon.svg",
    formIcon: process.env.PUBLIC_URL + "/assets/form-icon.svg",
    eventIcon: process.env.PUBLIC_URL + "/assets/event-icon.svg",
    workflowsIcon: process.env.PUBLIC_URL + "/assets/workflows.svg",
    dropdownIcon: process.env.PUBLIC_URL + "/assets/dropdown.svg",
    dropdownGreyIcon: process.env.PUBLIC_URL + "/assets/dropdown-gray.svg",
    descriptionIcon: process.env.PUBLIC_URL + "/assets/discription.svg",
    earthIcon: process.env.PUBLIC_URL + "/assets/earth.svg",
    participatesIcon: process.env.PUBLIC_URL + "/assets/participates.svg",
    timerIcon: process.env.PUBLIC_URL + "/assets/timer.svg",
    settingIcon: process.env.PUBLIC_URL + "/assets/setting-icon.png",
    watchIcon: process.env.PUBLIC_URL + "/assets/watch-icon.png",
    closeIcon: process.env.PUBLIC_URL + "/assets/close-icon.svg",
    decreaseIcon: process.env.PUBLIC_URL + "/assets/decrease-icon.svg",
    increaseIcon: process.env.PUBLIC_URL + "/assets/increase-icon.svg",
    Support: process.env.PUBLIC_URL + "/assets/support.svg",
    SupportIcon: process.env.PUBLIC_URL + "/assets/supportIcon.svg",
    teamIcon: process.env.PUBLIC_URL + "/assets/team-icon.svg",
    directionLightIcon: process.env.PUBLIC_URL + "/assets/direction-icon-light.svg",
    helpIcon: process.env.PUBLIC_URL + "/assets/help-icon.svg",
    integrationIcon: process.env.PUBLIC_URL + "/assets/integration-icon.svg",
}

export const toastError = (message, position) => {
    toast.error(message || "Something went wrong!", { position });
}

export const toastSuccess = (message, position = "top-right") => {
    toast.success("Changes Saved", { position, autoClose: 2000 });
}

export const toastWarning = (message, position) => {
    toast.warning(message, { position, });
}

export const calculateMinutes = (slots) => {
    return slots.every(p => {
        let from = p.from
        let to = p.to
        let diff = 0;
        if (from && to) {
            from = ConvertToSeconds(from);
            to = ConvertToSeconds(to);
            diff = Math.abs(to - from);
            return secondsTohhmmss(diff) >= 30;
        }
        return false;
    })
}

const ConvertToSeconds = (time) => {
    var splitTime = time.split(":");
    return splitTime[0] * 3600 + splitTime[1] * 60;
}

const secondsTohhmmss = (secs) => {
    var hours = parseInt(secs / 3600);
    var seconds = parseInt(secs % 3600);
    var minutes = parseInt(seconds / 60);
    return (hours * 60) + minutes;
}

export function debounce(func, wait, immediate) {
    var timeout;
    return function () {
        var context = this, args = arguments;
        var later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};