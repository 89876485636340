import React, { useState } from 'react'
import { FormGroup, Input, Modal, ModalBody, ModalFooter, Spinner } from 'reactstrap';
import "./UserLink.css"
import { images, toastSuccess } from "../../../utils/constants";
import { updateUser } from "../../../apis/user";

export default function UserLink({isSmallScreen, handleNavigation, setUserLogin, userLogin }) {
    const [personalLink, setPersonalLink] = useState(userLogin?.link);
    const [isOpenModel, setOpenModel] = useState(false);
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isEditable, setEditable] = useState(false);

    const cancelHandler = () => {
        setPersonalLink(userLogin?.link)
        setError('');
        setEditable(false);
    }
    const updatePersonalLinkHandler = async () => {
        const isLinkValid = /^[a-zA-Z0-9 -]+$/.test(personalLink);
        if (isLinkValid) {
            let link = personalLink.split(" ").join("-");
            try {
                setIsLoading(true)
                await updateUser({ name: userLogin.name, link: link, access_token: userLogin.accessToken });
                setUserLogin({ ...userLogin, link: link });
                setOpenModel(!isOpenModel);
                handleNavigation("3", "left", "/profiledetails");
                toastSuccess("", isSmallScreen ? "bottom-right" : "top-right");
                setIsLoading(false);
                setEditable(false);
            } catch (err) {
                setError(err.response?.data.message);
                setIsLoading(false);
            }
        }
        else setError("You are trying to use characters not compatible with a URL");
    }

    const personalLinkChangeHandler = (link) => {
        setPersonalLink(link);
        setError('');
    }

    return (
        <>
            {/* <Modal modalClassName="update-link-modal-container" className='update-link-modal' isOpen={isOpenModel} toggle={toggleModalHandler}>
                <ModalBody className='pt-3 pb-0 px-0'>
                    <FormGroup>
                        <Input className='txt-update-link' type="text" name="name"
                            value={personalLink}
                            onChange={e => personalLinkChangeHandler(e.target.value)}
                            placeholder="Enter link" />
                        <p className='update-modal-link-error text-danger mb-0 mt-1'>{error}</p>
                    </FormGroup>
                </ModalBody>
                <ModalFooter className='update-link-modal-footer border-0 p-0'>
                    <button className='btn-cancel my-0' onClick={toggleModalHandler}>Cancel</button>
                    <button className='btn-update my-0' onClick={updatePersonalLinkHandler} disabled={isLoading || personalLink === userLogin?.link}>
                        {isLoading ? <Spinner size="sm" color="light" /> : "Update"}
                    </button>
                </ModalFooter>
            </Modal> */}

            <div className='calendar-main-title-container'>
                <div className="back-button" onClick={() => handleNavigation("2", "left")}>
                    <img src={images.backIcon} alt="icon" />
                    <label className="calendar-header">Profile details</label>
                </div>
                <h1 className="calendar-main-title">Personal link</h1>
            </div>
            {/* <div className="personal-link-item-container p-3">
                <div className='personal-link-item d-flex justify-content-between'>
                    <div className="main-calendar-link-container text-start">
                        <span>/</span>
                        <Input className='txt-update-link' type="text" name="name"
                            value={personalLink}
                            onChange={e => personalLinkChangeHandler(e.target.value)}
                            placeholder="Enter link" />
                        <p className='update-modal-link-error text-danger mb-0 mt-1'>{error}</p>
                    </div>
                    <div className='btn-edit me-1' onClick={() => toggleModalHandler()}>Edit</div>
                </div>
            </div> */}

            <div className='calendar-link-component'>
                <div className="main-calendar-link-container me-1 d-flex">
                    {/* {isEditable ? <> */}
                    <span>/</span>
                    <Input
                        onFocus={() => setEditable(true)}
                        className="txt-calendar-link mb-0"
                        type="text" name="link"
                        onChange={e => personalLinkChangeHandler(e.target.value)}
                        value={personalLink}
                        placeholder="Enter calendar link" />
                    {/* </> : <p className="mb-0 text-truncate main-calendar-link">/{userLogin.link}</p> */}
                </div>
            </div>
            <p className='update-modal-link-error text-danger mb-0 mt-1'>{error}</p>
            {isEditable && <div className="editable-button-container m-2">
                <button type="button" className='btn-cancel' onClick={cancelHandler}>Cancel</button>
                <button type="button" className='btn-save' onClick={updatePersonalLinkHandler} disabled={isLoading || personalLink === userLogin?.link}>
                    {isLoading ? <Spinner size="sm" color="primary" /> : "Save"}
                </button>
            </div>}
        </>
    )
}